import request from "@/api/service";

const api = {
   page: '/event/enterpriseWechat/customer/getPage',
   select: '/event/enterpriseWechat/ewUser/select',
   sync: '/event/enterpriseWechat/customer/synchCustomer'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function getSelect(params ={}) {
   return request({
      url: api.select,
      method: 'POST',
      data: params
   })
}

export function syncCustomer(params) {
   return request({
      url: api.sync,
      method: 'POST',
      data: params
   })
}

