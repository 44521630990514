<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>微信昵称：</label>
            <el-input v-model="params.nickName" placeholder="微信昵称" clearable></el-input>
          </el-row>
          <el-row class="search-item">
            <label>姓名：</label>
            <el-input v-model="params.userName" placeholder="姓名" clearable></el-input>
          </el-row>
          <el-row class="search-item">
            <label>手机号：</label>
            <el-input v-model="params.mobile" placeholder="手机号" clearable></el-input>
          </el-row>
        </el-row>
        <el-row class="search-row">
        <!-- <el-row class="search-item">
            <label>客户来源：</label>
            <el-select placeholder="客户来源">
              <el-option></el-option>
            </el-select>
          </el-row>-->
          <el-row class="search-item">
            <label>所属员工：</label>
            <el-select v-model="params.firstUserId" @focus="getStaffList" placeholder="所属员工">
              <el-option v-for="item of staffOpt" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-row>
          <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
          <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-row type="flex" justify="space-between">
          <el-tabs v-model="params.status">
            <el-tab-pane label="全部"></el-tab-pane>
            <el-tab-pane label="正常" name="YES"></el-tab-pane>
            <el-tab-pane label="流失" name="NO"></el-tab-pane>
          </el-tabs>
          <el-row class="right-btn">
            <el-button @click="handleSync" class="bg-gradient" type="primary">同步客户</el-button>
          </el-row>
        </el-row>
        <el-table
            v-loading="loading"
            :data="tableData"
            style="width: 100%"
            :stripe="true"
            border fit>
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column label="头像" min-width="50">
            <template slot-scope="scope">
              <el-avatar :size="50" :src="scope.row.avatar"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column prop="nickName" label="微信昵称" min-width="70"></el-table-column>
          <el-table-column label="客户来源" min-width="70"></el-table-column>
          <el-table-column prop="userName" label="姓名"></el-table-column>
          <el-table-column prop="mobile" label="手机号"></el-table-column>
          <el-table-column prop="firstUserId" label="所属员工"></el-table-column>
          <el-table-column prop="tagName" label="标签群组"></el-table-column>
          <el-table-column prop="status" label="状态"></el-table-column>
          <!-- <el-table-column label="操作">
            <template>
              <el-button type="text"><el-link type="primary">删除</el-link></el-button>
            </template>
          </el-table-column>-->
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getPage, getSelect, syncCustomer } from "@/api/data/wechat/customer/customer";
import { mapState } from 'vuex'
export default {
  name: 'liveCode',
  data() {
    return {
      crumbs: new Map([
        ['数据中台'], ['企业微信'], ['客户管理'], ['客户信息']
      ]),
      tableData: [],
      staffOpt: [],
      loading: false,
      total: 0,
      limit: 0,
      page: 1,
      params: {
        nickName: '',
        userName: '',
        mobile: '',
        status: '',
        firstUserId: ''
      }
    }
  },
  computed: { ...mapState(['hotelInfo']) },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getCustomerList()
  },
  methods: {
    // 获取客户列表
    getCustomerList() {
      const query = `?page=${ this.page }&limit=${ this.limit }`,
            params = { ...this.params, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      getPage(query, params).then(({ success, records, total }) => {
        if (!success) return
        this.total = total
        this.tableData = records
      })
    },
    // 获取员工下拉列表
    getStaffList() {
      if (this.staffOpt.length > 0) return
      const params = { hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      getSelect(params).then(({ success, records }) => {
        if (!success) return
        this.staffOpt = records.map(i => ({ id: i.id, name: i.name }))
      })
    },
    // 查询客户列表
    handleQuery(bool) {
      this.page = 1
      if (bool) return this.getCustomerList()
      for (const k in this.params) this.params[k] = ''
    },
    // 改变每页数
    changePagNum(num) {
      this.limit = num
      this.getCustomerList()
    },
    // 改变当前页
    changeCurrPage(num) {
      this.page = num
      this.getCustomerList()
    },
    // 处理同步
    handleSync() {
      setTimeout(() => {
        this.$message({ message: '客户信息正在同步中，稍后关注同步结果' })
      }, 1000)
      const params = { hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      syncCustomer(params).then(({ success }) => {
        if (!success) return
        this.getCustomerList()
        this.$message({ message: '同步客户成功！', type: 'success' })
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
